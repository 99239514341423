import { useEffect, useState } from "react";
import { HandThumbUpIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { AvatarIcon } from "../utils/images.util";

import useIsDesktop from "../hooks/useIsDesktop";

import { getArticles, updateArticleLikes } from "../services/article.service";

import Loading from "../components/loading.component";
import { setLikes } from "../redux/actions";
import Pagination from "react-js-pagination";

const Discover = () => {
  const dispatch = useDispatch();

  const isDesktop = useIsDesktop();

  const likes = useSelector((state) => state.likes);

  const [articles, setArticles] = useState([]);

  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    setLoading(true);
    getArticles({
      page: currentPage - 1,
      size: 6,
    }).then((res) => {
      setArticles(res.data.articles);
      setTotalItems(res.data.totalItems);
      setLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  }, [currentPage]);

  const handleUpdateLikes = async (a) => {
    let temp = [];
    await updateArticleLikes(
      a._id,
      likes && likes.includes(a._id) ? a.likes - 1 : a.likes + 1
    ).then((res) => {
      if (likes && likes.includes(a._id)) {
        temp = likes.filter((e) => e !== a._id);
      } else {
        temp.push(a._id);
      }
      setArticles(
        articles.map((a) =>
          a._id === res.data.result._id ? res.data.result : a
        )
      );
      dispatch(setLikes(temp));
    });
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <div
        className="min-vh-100 d-flex flex-column justify-content-center"
        style={{ marginTop: 120 }}
      >
        <div className="glass-wrapper">
          <div className="circle-1"></div>
          <div className="circle-2"></div>
        </div>
        <div className="container" style={{ marginTop: !isDesktop && "25%" }}>
          <h1 className="text-center">Discover Places</h1>
          <h5 style={{ opacity: 0.5, marginTop: 10 }} className="text-center">
            Skim through all the articles our writers have written.
          </h5>
          <ul className="discover-articles mt-5">
            {articles.map((article) => (
              <li key={article._id}>
                <div className="main-img-wrapper">
                  <Link to={`/article/${article.slug}`}>
                    <img src={article.src} alt={article.slug} />
                  </Link>
                </div>
                <div className="content">
                  <Link to={`/article/${article.slug}`}>
                    <h4>{article.title}</h4>
                  </Link>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: article.content.substring(0, 200) + "...",
                    }}
                  />
                  <div className="hstack gap-1 author-wrapper">
                    <div className="img-wrapper">
                      <img src={AvatarIcon} alt={article.author} />
                    </div>
                    <div className="author-details">
                      <p className="name">{article.author}</p>
                      <p className="date">
                        {moment(article.published_date).format("ll")}
                      </p>
                    </div>
                  </div>
                  <div className="hstack action-wrapper mt-3">
                    <div
                      className="likes"
                      onClick={() => handleUpdateLikes(article)}
                    >
                      <HandThumbUpIcon />
                      <p>{article.likes}</p>
                    </div>
                    <p className="ms-auto my-auto">
                      {article.readTime} min read
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={6}
            totalItemsCount={totalItems}
            pageRangeDisplayed={5}
            itemClass="page-item"
            linkClass="page-link"
            prevPageText="‹"
            nextPageText="›"
            onChange={(e) => setCurrentPage(e)}
          />
        </div>
      </div>
    </>
  );
};

export default Discover;
