import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { HandThumbUpIcon } from "@heroicons/react/24/solid";
import {
  ArrowTrendingUpIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";

import { AvatarIcon } from "../utils/images.util";

import useIsDesktop from "../hooks/useIsDesktop";

import {
  getArticle,
  getLatestArticles,
  getTags,
  updateArticleLikes,
} from "../services/article.service";
import { setLikes } from "../redux/actions";

import Loading from "../components/loading.component";

const Article = () => {
  const dispatch = useDispatch();

  const { slugParam } = useParams();

  const { colorTheme, likes } = useSelector((state) => state);

  const isDesktop = useIsDesktop();

  const [article, setArticle] = useState(undefined);
  const [articles, setArticles] = useState([]);
  const [tags, setTags] = useState([]);

  const [loading, setLoading] = useState(true);

  const handleUpdateLikes = async (a) => {
    let temp = [];
    await updateArticleLikes(
      a._id,
      likes && likes.includes(a._id) ? a.likes - 1 : a.likes + 1
    ).then((res) => {
      if (likes && likes.includes(a._id)) {
        temp = likes.filter((e) => e !== a._id);
      } else {
        temp.push(a._id);
      }
      setArticle(res.data.result);
      dispatch(setLikes(temp));
    });
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    getArticle(slugParam)
      .then((res) => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        setArticle(res.data);

        getTags()
          .then((tagRes) => {
            setTags(tagRes.data);

            getLatestArticles()
              .then((res) => {
                setArticles(res.data);
                setLoading(false);
              })
              .catch((err) => setLoading(false));
          })
          .catch((err) => setLoading(false));
      })
      .catch((err) => setLoading(false));
  }, [slugParam]);

  return loading ? (
    <Loading />
  ) : (
    <div className="min-vh-100 single-article" style={{ marginTop: 92 }}>
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.meta_desc} />
      </Helmet>
      <div
        className="header-wrapper"
        style={{
          backgroundImage: isDesktop
            ? colorTheme === "light"
              ? "linear-gradient(to right, rgba(var(--dark-rgb),1) 60%, rgba(var(--dark-rgb),0))"
              : "linear-gradient(to right, rgba(var(--light-accent-rgb),1) 60%, rgba(var(--light-accent-rgb),0))"
            : "none",
        }}
      >
        <div className="container">
          <div className="content">
            <h2>{article.title.replace(/((?:.*?\s){5}.*?)\s/g, "$1\n")}</h2>
            <div className="author-wrapper">
              <hr />
              <div className="hstack gap-1">
                <div className="author-img-wrapper">
                  <img src={AvatarIcon} alt={article.author} />
                </div>

                <div className="author-details">
                  <p className="name">{article.author}</p>
                  <p className="date">
                    {moment(article.published_date).format("ll")} |{" "}
                    {article.readTime} min read
                  </p>
                </div>
                <div className="hstack action-wrapper ms-auto">
                  <div
                    className="likes"
                    onClick={() => handleUpdateLikes(article)}
                  >
                    <HandThumbUpIcon />
                    <p>{article.likes}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="img-wrapper">
          <img src={article.src} alt={article.title} />
        </div>
      </div>
      <div className="container py-5">
        <div className="content">
          <div className="row">
            <div className="col-lg">
              <p dangerouslySetInnerHTML={{ __html: article.content }} />
              <ul className="article-tags-wrapper">
                {article.tags.splice(0, 5).map((t) => (
                  <li key={t.id}>
                    <Link to={`/search?tagged=${t.text}`}>{t.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
            {isDesktop && (
              <div className="col-lg-4">
                <div className="tags-wrapper">
                  <h5>
                    <RectangleStackIcon /> Discover more tags
                  </h5>
                  <hr />
                  <ul>
                    {tags.map((t) => (
                      <li key={t.id}>
                        <Link to={`/search?tagged=${t.text}`}>{t.text}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="popular-articles mt-5">
                  <div className="article hstack">
                    <h5>
                      <ArrowTrendingUpIcon /> Popular articles
                    </h5>
                  </div>
                  {articles.map((d) => (
                    <div
                      key={d._id}
                      className="article hstack align-items-start"
                    >
                      <div className="content">
                        <Link to={`/article/${d.slug}`}>
                          <h4>{d.title}</h4>
                        </Link>
                        <div className="hstack gap-1 author-wrapper">
                          <div className="img-wrapper">
                            <img src={AvatarIcon} alt={d.author} />
                          </div>
                          <div className="author-details">
                            {d.author} | {moment(d.published_date).format("ll")}
                          </div>
                        </div>
                      </div>
                      <div className="ms-auto main-img-wrapper">
                        <Link to={`/article/${d.slug}`}>
                          <img src={d.src} alt={d.slug} />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;
