import { useEffect, useState } from "react";
import {
  HandThumbUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import AliceCarousel from "react-alice-carousel";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { AvatarIcon } from "../utils/images.util";

import useIsDesktop from "../hooks/useIsDesktop";

import {
  getAuthorPicks,
  getLatestArticles,
  getTrendingArticles,
  updateArticleLikes,
} from "../services/article.service";

import Loading from "../components/loading.component";
import { setLikes } from "../redux/actions";

const Home = () => {
  const dispatch = useDispatch();

  const isDesktop = useIsDesktop();

  const likes = useSelector((state) => state.likes);

  const [authorPickedArticles, setAuthorPickedArticles] = useState([]);
  const [trendingArticles, setTrendingArticles] = useState([]);
  const [latestArticles, setLatestArticles] = useState([]);

  const [currentPick, setCurrentPick] = useState(undefined);

  const [loading, setLoading] = useState(true);

  const [currentId, setCurrentId] = useState(0);

  useEffect(() => {
    getAuthorPicks().then((res) => {
      setAuthorPickedArticles(res.data);
      setCurrentPick(res.data[0]);

      getTrendingArticles().then((res) => {
        setTrendingArticles(res.data);

        getLatestArticles().then((res) => {
          setLatestArticles(res.data);
          setLoading(false);
        });
      });
    });
  }, []);

  useEffect(() => {
    setCurrentPick(authorPickedArticles[currentId]);
  }, [currentId, authorPickedArticles]);

  const handleUpdateLikes = async (a) => {
    let temp = [];
    await updateArticleLikes(
      a._id,
      likes && likes.includes(a._id) ? a.likes - 1 : a.likes + 1
    ).then((res) => {
      if (likes && likes.includes(a._id)) {
        temp = likes.filter((e) => e !== a._id);
      } else {
        temp.push(a._id);
      }
      updateArticle(res.data.result);
      dispatch(setLikes(temp));
    });
  };

  const updateArticle = (article) => {
    setAuthorPickedArticles(
      authorPickedArticles.map((a) => (a._id === article._id ? article : a))
    );

    setTrendingArticles(
      trendingArticles.map((a) => (a._id === article._id ? article : a))
    );

    setLatestArticles(
      latestArticles.map((a) => (a._id === article._id ? article : a))
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      {latestArticles.length > 0 && (
        <div className="min-vh-100 d-flex flex-column justify-content-center">
          <div
            className="container"
            style={{ marginTop: isDesktop ? "7%" : "25%" }}
          >
            <h1 className="text-center">Latest Articles</h1>
            <h5 style={{ opacity: 0.5, marginTop: 10 }} className="text-center">
              Discover newly written articles about places you might never have
              heard of!
            </h5>
            <br />
            <div className="row">
              <div className="col-lg">
                <div className="latest-article">
                  <div className="main-img-wrapper">
                    <Link to={`/article/${latestArticles[0].slug}`}>
                      <img
                        src={latestArticles[0].src}
                        alt={latestArticles[0].slug}
                      />
                    </Link>
                  </div>
                  <div className="content">
                    <Link to={`/article/${latestArticles[0].slug}`}>
                      <h4>{latestArticles[0].title}</h4>
                    </Link>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          latestArticles[0].content.substring(0, 200) + "...",
                      }}
                    />
                    <div className="hstack gap-1 author-wrapper">
                      <div className="img-wrapper">
                        <img src={AvatarIcon} alt={latestArticles[0].author} />
                      </div>
                      <div className="author-details">
                        <p className="name">{latestArticles[0].author}</p>
                        <p className="date">
                          {moment(latestArticles[0].published_date).format(
                            "ll"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="hstack action-wrapper mt-3">
                      <div
                        className="likes"
                        onClick={() => handleUpdateLikes(latestArticles[0])}
                      >
                        <HandThumbUpIcon />
                        <p>{latestArticles[0].likes}</p>
                      </div>
                      <p className="ms-auto my-auto">
                        {latestArticles[0].readTime} min read
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {latestArticles.length > 1 && (
                <div className="col-md-auto col-lg-5 d-block">
                  {latestArticles.slice(1).map((d) => (
                    <div
                      key={d._id}
                      className="latest-article latest-article-mini hstack gap-3"
                    >
                      <div className="content">
                        <Link to={`/article/${d.slug}`}>
                          <h4>{d.title}</h4>
                        </Link>
                        <div className="hstack gap-1 author-wrapper">
                          <div className="img-wrapper">
                            <img src={AvatarIcon} alt={d.author} />
                          </div>
                          <div className="author-details d-flex align-items-center my-auto">
                            <p className="name my-auto">
                              {d.author} |{" "}
                              {moment(d.published_date).format("ll")}
                            </p>
                          </div>
                        </div>
                        <div className="hstack action-wrapper">
                          <div
                            className="likes"
                            onClick={() => handleUpdateLikes(d)}
                          >
                            <HandThumbUpIcon />
                            <p>{d.likes}</p>
                          </div>
                          <p className="mx-2 my-auto">{d.readTime} min read</p>
                        </div>
                      </div>
                      <div className="ms-auto main-img-wrapper">
                        <Link to={`/article/${d.slug}`}>
                          <img src={d.src} alt={d.slug} />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div style={{ marginTop: "5%" }}>
        <div
          className="container p-5"
          style={{
            backgroundColor: "rgba(var(--dark-rgb), 0.03)",
            borderRadius: 30,
          }}
        >
          <h1 className="text-center">Top trending places</h1>
          <h5 style={{ opacity: 0.5, marginTop: 10 }} className="text-center">
            Discover new trending topics!
          </h5>
          <br />
          <div className="">
            <div>
              <AliceCarousel
                responsive={{
                  768: { items: 1 },
                  992: { items: 3 },
                }}
                mouseTracking
                disableDotsControls
                items={trendingArticles.map((d) => (
                  <Link to={`/article/${d.slug}`}>
                    <div className="trending-article">
                      <div className="img-wrapper">
                        <img src={d.src} alt={d.slug} />
                      </div>
                      <div className="content">
                        <h4>{d.title}</h4>
                        <p>
                          {d.author} | {moment(d.published_date).format("ll")}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
                renderPrevButton={() => {
                  return (
                    <button
                      className="btn-slide"
                      style={{
                        display: "initial",
                        marginTop: 10,
                        justifyContent: "revert",
                      }}
                    >
                      <ChevronLeftIcon />
                    </button>
                  );
                }}
                renderNextButton={() => {
                  return (
                    <button
                      className="btn-slide"
                      style={{
                        display: "initial",
                        marginTop: 10,
                        justifyContent: "revert",
                      }}
                    >
                      <ChevronRightIcon />
                    </button>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: "5% 0" }}>
        <div className="glass-wrapper">
          <div className="circle-1"></div>
          <div className="circle-2"></div>
        </div>
        <div className="container">
          <h1>Editor's pick</h1>
          <h5 style={{ opacity: 0.5, marginTop: 10 }}>
            Discover the most outstanding places from all over the planet!
          </h5>
          <div className="row home-article-wrapper mt-5">
            <div className={`col-lg-5 my-auto order-${isDesktop ? "0" : "1"}`}>
              {currentPick && (
                <div className="home-article">
                  <h4>
                    <Link to={`/article/${currentPick.slug}`}>
                      {currentPick.title.substring(0, 50) + "..."}
                    </Link>
                  </h4>
                  <div className="hstack gap-1 author-wrapper">
                    <div className="img-wrapper">
                      <img src={AvatarIcon} alt={currentPick.author} />
                    </div>
                    <div className="author-details">
                      <p className="name">{currentPick.author}</p>
                      <p className="date">
                        {moment(currentPick.published_date).format("ll")}
                      </p>
                    </div>
                  </div>
                  <div className="hstack action-wrapper mt-3">
                    <div
                      className="likes"
                      onClick={() => handleUpdateLikes(currentPick)}
                    >
                      <HandThumbUpIcon />
                      <p>{currentPick.likes}</p>
                    </div>
                    <p className="ms-auto my-auto">
                      {currentPick.readTime} min read
                    </p>
                  </div>
                </div>
              )}
              <div className="hstack gap-3 mt-4 slide-btn-wrapper">
                <button
                  className="btn-slide"
                  onClick={() =>
                    setCurrentId(
                      currentId === 0
                        ? authorPickedArticles.length - 1
                        : currentId - 1
                    )
                  }
                >
                  <ChevronLeftIcon />
                </button>
                <button
                  className="btn-slide"
                  onClick={() =>
                    setCurrentId(
                      currentId === authorPickedArticles.length - 1
                        ? 0
                        : currentId + 1
                    )
                  }
                >
                  <ChevronRightIcon />
                </button>
              </div>
            </div>
            {currentPick && (
              <div
                className={`col-md-auto col-lg article-img order-${
                  isDesktop ? "1" : "0"
                }`}
              >
                <Link to={`/article/${currentPick.slug}`}>
                  <img src={currentPick.src} alt={currentPick.slug} />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
