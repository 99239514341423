import { useSelector } from "react-redux";

import { LogoColored, LogoWhite } from "../utils/images.util";

const Footer = () => {
  const colorTheme = useSelector((state) => state.colorTheme);

  return (
    <footer className="d-flex justify-content-center text-center mt-5">
      <div className="container">
        <a href="/" className="text-decoration-none">
          <img
            src={colorTheme === "light" ? LogoColored : LogoWhite}
            alt="Trvelling"
            width="120px"
          />
        </a>
        <div className="footnote">
          Explore the world with us - one destination at a time. Let our blog be
          your guide to finding the best places to visit, things to do, and tips
          for making the most out of your journey. Happy travels! Please note
          that the information provided on this website is for informational
          purposes only and should not be taken as professional travel advice.
          Always check with local authorities and your government's travel
          advisory before planning any trips.
        </div>
        <div className="footnote" style={{ fontSize: 12 }}>
          &copy; 2025 Trvelling. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
